import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "underscore";
import { checkTokensStorage } from "../context/checkTokens";
import {
  Button,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useAuth } from "../context/auth";
import { remember, checkRemember } from "../context/rememberMe";
import LoginForm from "../components/LoginForm";
// import { wayfless } from "../context/wayfless";

const axios = require("axios");
// const pk = "9lsoo3-4924-4kkkka-llikh2";
// const store = require("store");
// const jwt = require("jsonwebtoken");
const LoginMdl = ({
  props,
  show,
  setLoginShow,
  setSignupShow,
  setErrorShow,
  setErrors,
  want_url = "/Home",
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberme, setRememberme] = useState(false);

  const { setAuthTokens } = useAuth();

  var loginObj = checkTokensStorage();
  want_url = want_url || "/Home";
  // console.log(want_url);
  const handleLogin = (event) => {
    remember(rememberme, username, password);

    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Auth/Login"
          : "/Auth/Login",
        {
          username: username,
          password: password,
          credentials: "include",
        },
        { withCredentials: true }
      )

      .then((res) => {
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            setAuthTokens(res.data);
            setLoginShow(false);
            window.location.href = want_url; //"/Home";
          } else {
            //todo setError;
            setErrors({ message: "Invalid Username or Password" });
            setErrorShow(true);
          }
        } else {
          // const error = new Error(res.error);
          setErrors({ message: "Invalid Username or Password" });
          setErrorShow(true);
        }
        //callback for the response
      })
      .catch((error) => {
        setErrors({ message: "Invalid Username or Password" });
        setErrorShow(true);
      });

    event.preventDefault();
  };
  useEffect(() => {
    document.title = "Trip Medical Database";
    document.body.classList.remove("homepage");

    checkRemember(setUsername, setPassword, setRememberme);
  }, []);
  return (
    <>
      <Modal
        size="lg"
        id="login-mdl"
        show={show}
        onHide={(e) => setLoginShow(false)}
        onShow={(e) => {
          let el = document.getElementById("login-mdl");
          if (el) el.classList.add("modal-dialog--login");
          // let wf = document.getElementById("wayfinderEmbedded");
          // if (wf === null)
          //   wayfless(
          //     window,
          //     document,
          //     "https://wayfinder.openathens.net/embed/",
          //     "/loader.js",
          //     "7ca0328c-55c6-4bfe-b851-0e1b82b9bd5b"
          //     // "c405b315-2492-403b-a8af-d279e7d52798"
          //   );
        }}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <div className="logo">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 165.17 104.41"
            >
              <g>
                <path
                  d="M59.36,9.66V6.2Q59.36.62,56,.62H3.34Q0,.62,0,6.2V9.66q0,5.56,3.34,5.57H21.05v61.9H38.31V15.23H56q3.34,0,3.34-5.57M78.13,21.79q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79,17.79,17.79,0,0,0-.5-2.06c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4A6.56,6.56,0,0,0,60.31,23a38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V77.13H66.88V41.87c2.23-3.72,5.19-5.58,8.91-5.58a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V99.49h16.48v-21a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,68.85a15.68,15.68,0,0,1-7.8-2.12V41.87q3.78-6.59,9.13-6.58,9,0,9,16.78T138.11,68.85M99.08,0H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V3.57Q105.76,0,99.08,0m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V77.13h16.48V26.42q0-3.57-6.68-3.57"
                  fill="#533764"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M37.33,77.53v.58Q37.33,82,31,82H27.26q-6.36,0-6.36-3.9v-.58Z"
                  fill="#63c608"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M67.19,77.53v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
                  fill="#0e6cbb"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M106,77.53v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                  fill="#00a89d"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M130.7,100.06v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
                  fill="#eec82f"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M106,11.83v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                  fill="#ba390d"
                  fillRule="evenodd"
                ></path>
              </g>
            </svg>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => setLoginShow(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <h2 className="modal-title" id="loginModalLabel">
            Login to your account
          </h2>
          <LoginForm
            logindata={{ username: username, password: password }}
            handleLogin={handleLogin}
            setUsername={setUsername}
            setPassword={setPassword}
            setRememberme={setRememberme}
            rememberme={rememberme}
            mdl={true}
          />
          <hr></hr>
          <h5>
            {" "}
            or{" "}
            <img
              style={{
                overflow: "hidden",
                maxHeight: "70px",
                maxWidth: "120px",
                align: "right",
              }}
              src={require("../components/assets/openathens.png")}
            ></img>
          </h5>
          {loginObj.chkInstitution.institution_name ? (
            <div>
              {" "}
              <div className="mt-2 alert alert-secondary" role="alert">
                {loginObj.chkInstitution.institution_name}{" "}
                <a className="alert-link" href="/Logout">
                  Logout?
                </a>
              </div>
            </div>
          ) : (
            <div className="form-footer">
              <Link
                className="btn btn-primary btn-lg pull-right btn btn-primary"
                to="/Login"
              >
                <i className="fa fa-university"></i> Institution Login
              </Link>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginMdl;
