import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
  Card,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import Sidebar from "../components/SidebarRegistration";
import InstitutionReportMdl from "./CMS/institutionReportMdl";

import _ from "underscore";
const axios = require("axios");
const store = require("store");

const Profile = (props) => {
  const [validated, setValidated] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [hasSuccess, setSuccess] = useState(false);
  const [dupEmail, setDupEmail] = useState(false);
  const [selectValid, setSelectValid] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(0);
  const [clinicalarea, setClinicalarea] = useState("");
  const [profession, setProfession] = useState(0);
  const [institution, setInstitution] = useState(0);
  const [institutionAdmin, setInstitutionAdmin] = useState("");
  const [institutionVerified, setInstitutionVerified] = useState("");
  const [institutionVerifiedMsg, setInstitutionVerifiedMsg] = useState("");
  const [interests, setInterests] = useState("");
  const [userid, setUserid] = useState(0);
  const [isloggedin, setLoggedin] = useState(0);
  const [signupcode, setSignupcode] = useState("");
  const [instname, setInstName] = useState("");
  const [statsdata, setStatsData] = useState([]);
  const [statstotal, setStatsTotal] = useState(0);
  const [statshow, setStatsShow] = useState(false);
  const [currentinstid, setInstid] = useState("");
  const [currentPageStats, setCurrentPageStats] = useState(1);

  async function fetchData() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Auth/getProfileData"
          : "/Auth/getProfileData",
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
          setDefaults(res.data);
          setLoggedin(1);
        } else {
          const error = new Error(res.message);
          setErrors(res.message);
          setLoggedin(0);
        }
      })
      .catch((error) => {
        if (!_.isEmpty(error)) {
          setErrors(error);
          setLoggedin(0);
        }
      });
  }

  /**
   * setDefault values of user profile
   * @param {*} params
   */
  function setDefaults(params) {
    var user = _.first(params.user_data);
    if (user) {
      setUserid(user.user_id);
      setFirstname(user.firstname);
      setLastname(user.lastname);
      setEmail(user.email);
      setProfession(
        _.where(params.professions, {
          value: user.professionid,
        })
      );
      setCountry(
        _.where(params.countries, {
          value: user.countryid,
        })
      );

      if (!_.isEmpty(user.clinicalareaids)) {
        var getclinicalarea = !_.isEmpty(user.clinicalareaids)
          ? user.clinicalareaids.split(",")
          : [];

        setClinicalarea(
          _.chain(getclinicalarea)
            .map(function (val) {
              return _.findWhere(params.clinical_areas, {
                value: parseInt(val, 10),
              });
            })
            .value()
        );
      }
      if (!_.isUndefined(user.institutionids)) {
        setInstitution(
          _.chain(user.institutionids)
            .map(function (val) {
              if (val.institution_admin === true) {
                setInstitutionAdmin(
                  _.findWhere(params.institutions, {
                    value: parseInt(val.institution_id, 10),
                  })
                );
              }
              if (val.institution_verified === true) {
                setInstitutionVerified(
                  _.findWhere(params.institutions, {
                    value: parseInt(val.institution_id, 10),
                  })
                );
              }
              return _.findWhere(params.institutions, {
                value: parseInt(val.institution_id, 10),
              });
            })
            .value()
        );
      }

      setInterests(user.interests);
    }
  }
  /**
   * fetchreport
   * @param {*} id
   */
  async function fetchInstitutionStats(id, page = 1) {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/getInstitutionStats"
          : "/Administrator/getInstitutionStats",
        {
          params: {
            page: page,
            institution_id: id,
            rows: 10,
          },
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setStatsData(res.data);
          setStatsTotal(res.data.total);
          setStatsShow(true);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoading(false);
      });
  }
  const handleInstitutionPageChange = (page) => {
    setCurrentPageStats(page);
    fetchInstitutionStats(currentinstid, page);
  };
  const handleClose = () => {
    setStatsShow(false);
    setCurrentPageStats(1);
  };

  const handleReport = (e, id, inst_name) => {
    setInstName(inst_name);
    setInstid(id);
    fetchInstitutionStats(id);
    setStatsShow(false);
    e.preventDefault();
  };

  useEffect(() => {
    document.body.classList.remove("homepage");
    if (!_.isUndefined(props.isPro) ? props.isPro : false)
      document.body.classList.add("pro-user");
    else document.body.classList.add("free-user");
    fetchData();
  }, []);
  const selectValidCheck = () => {
    if (_.isEmpty(email)) {
      setSelectValid("Missing mandatory field : Email!");
      return false;
    }
    if (_.isEmpty(firstname)) {
      setSelectValid("Missing mandatory field : Firstname");
      return false;
    }
    if (_.isEmpty(lastname)) {
      setSelectValid("Missing mandatory field : Lastname");
      return false;
    }
    if (_.isEmpty(country)) {
      setSelectValid("Missing mandatory field : Country!");
      return false;
    }

    if (_.isEmpty(profession)) {
      setSelectValid("Missing mandatory field : Profession!");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false || selectValidCheck() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setSelectValid("");
      axios
        .post(
          !_.isUndefined(process.env.REACT_APP_API_URL)
            ? process.env.REACT_APP_API_URL + "/Auth/updateProfile"
            : "/Auth/updateProfile",
          {
            params: {
              userid: userid,
              email: email,
              firstname: firstname,
              lastname: lastname,
              country: country,
              clinicalarea: clinicalarea,
              profession: profession,
              institution: institution,
              interests: interests,
              signupcode: signupcode,
            },
          },
          { withCredentials: true }
        )

        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === true) {
              setSuccess(true);
              setDupEmail(false);

              if (!_.isUndefined(store.get("tokens"))) {
                var existingProfile =
                  !_.isNull(store.get("tokens")) && store.get("tokens") !== ""
                    ? JSON.parse(store.get("tokens"))
                    : null;
                if (!_.isUndefined(res.data.verified_message)) {
                  if (res.data.verified_message > 0) {
                    existingProfile.institution_pro = res.data.verified_message;
                  }
                }
                if (!_.isUndefined(existingProfile.firstname)) {
                  existingProfile.firstname = firstname;
                  existingProfile.lastname = lastname;

                  store.set("tokens", JSON.stringify(existingProfile));
                }
              }
              if (form.getAttribute("data-source") == "btnContinue") {
                window.setTimeout(() => {
                  setSuccess(false);
                  setDupEmail(false);
                  props.history.push({
                    pathname: "/Home",
                  });
                }, 2000);
              } else {
                window.setTimeout(() => {
                  setSuccess(false);
                  setDupEmail(false);
                  props.history.push({
                    pathname: "/Profile",
                  });
                }, 2000);
              }
            } else {
              setDupEmail(true);
            }
          } else {
            // const error = new Error(res.error_message);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      e.preventDefault();
    }
    setValidated(true);
  };

  var user_data_set = [],
    clinical_areas_set = [],
    professions_set = [],
    institutions_set = [],
    countries_set = [];
  if (!_.isEmpty(data)) {
    user_data_set = data.user_data ? data.user_data : {};
    clinical_areas_set = data.clinical_areas ? data.clinical_areas : [];
    countries_set = data.countries ? data.countries : [];
    professions_set = data.professions ? data.professions : [];
    institutions_set = data.institutions ? data.institutions : [];
  }
  return (
    <main role="main" className="main mt-2">
      <Row></Row>
      {hasError ? (
        <Row>
          <Col md="1"></Col>
          <Col md="9">
            <Alert variant="warning">
              To access this page you need to{" "}
              <a
                href="/#"
                onClick={(e) => {
                  var element = document.getElementById("nav-login");
                  element.click();
                  e.preventDefault();
                }}
              >
                Login!
              </a>
            </Alert>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md="3">
            <Sidebar {...props} />
          </Col>
          <Col md="6">
            {loading ? (
              <div className="pull-center">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <div>
                {isloggedin == 1 ? (
                  <Card>
                    <Card.Body>
                      <Card.Header className="label label-success">
                        <h2>Your Profile</h2> update and save
                      </Card.Header>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                        autoComplete="Off"
                      >
                        <Form.Group controlId="profile.email">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="email e.g. example@example.com"
                            autoComplete="off"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a correct email
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="ctrlfirstname">
                          <Form.Label>Firstname</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Firstname"
                            name="firstname"
                            value={!_.isEmpty(firstname) ? firstname : ""}
                            onChange={(e) => setFirstname(e.target.value)}
                            autoComplete="off"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a correct firstname
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="ctrllastname">
                          <Form.Label>Lastname</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Lastname"
                            name="lastname"
                            value={!_.isEmpty(lastname) ? lastname : ""}
                            onChange={(e) => setLastname(e.target.value)}
                            required={true}
                            autoComplete="off"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a correct lastname
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Country</Form.Label>
                          <Select
                            className="single-multi-select col-sm-6"
                            classNamePrefix="select"
                            value={country}
                            onChange={(selectedOptions) =>
                              setCountry(selectedOptions)
                            }
                            isSearchable={true}
                            name="countries"
                            options={countries_set}
                            style={{ borderColor: "red" }}
                          />
                        </Form.Group>
                        <Form.Group controlId="ctrl.clinicalarea">
                          <Form.Label>Clinical Area</Form.Label>
                          <Select
                            // defaultValue={[colourOptions[2], colourOptions[3]]}
                            isMulti
                            name="clinicalarea"
                            options={clinical_areas_set}
                            onChange={(selectedOptions) =>
                              setClinicalarea(selectedOptions)
                            }
                            value={clinicalarea}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </Form.Group>
                        <Form.Group controlId="ctrl.profession">
                          <Form.Label>Profession</Form.Label>
                          <Select
                            className="basic-single col-sm-4"
                            classNamePrefix="select"
                            onChange={(selectedOptions) =>
                              setProfession(selectedOptions)
                            }
                            isSearchable={true}
                            name="profession"
                            value={profession}
                            options={professions_set}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="ctrl.profession">
                          <Form.Label>
                            Institution (Optional): <br />
                            Choose your institution(s) from the drop-down menu
                            below.The system will search your institution as you
                            type. NOTE: this is optional and can be ignored.
                          </Form.Label>
                          <Select
                            isMulti
                            name="institution"
                            options={institutions_set}
                            onChange={(selectedOptions) =>
                              setInstitution(selectedOptions)
                            }
                            value={institution}
                            className="basic-multi-select col-sm-6"
                            classNamePrefix="select"
                          />
                          {institutionVerified ? (
                            <Form.Group controlId="ctrl.signup">
                              <br></br>
                              <Form.Label>
                                <i className="fa fa-check-circle alert-success"></i>{" "}
                                Verified Institution:{" "}
                              </Form.Label>{" "}
                              {" " + institutionVerified.label}
                              {institutionVerifiedMsg && (
                                <div className="mb-2 alert alert-danger">
                                  <div>
                                    Your institution has been verified. You will
                                    be redirected to login in order to use the
                                    institution Pro access.
                                  </div>
                                </div>
                              )}
                            </Form.Group>
                          ) : (
                            <Form.Group controlId="ctrl.signup">
                              <br></br>
                              <Form.Label>
                                Institution Signup Code (Optional): <br />
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="signupcode"
                                value={signupcode}
                                onChange={(e) => setSignupcode(e.target.value)}
                                placeholder="Signup Code"
                              />
                            </Form.Group>
                          )}
                          {institutionAdmin && (
                            <Table>
                              <thead>
                                <tr>
                                  <th>Institution Admin Report</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{institutionAdmin.label}</td>
                                  <td>
                                    <Button
                                      variant="info"
                                      onClick={(e) => {
                                        handleReport(
                                          e,
                                          institutionAdmin.value,
                                          institutionAdmin.label
                                        );
                                      }}
                                      size="sm"
                                    >
                                      Usage Report{" "}
                                      <i className="fa fa-bar-chart"></i>
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </Form.Group>
                        <Form.Group controlId="ctrl.search">
                          <Form.Label>
                            Automated search <br /> Enter search terms of
                            interest below and we'll send you new content, based
                            on these terms, every month. An easy way to keep up
                            to date with new evidence in your area
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            name="interests"
                            value={!_.isEmpty(interests) ? interests : ""}
                            onChange={(e) => setInterests(e.target.value)}
                            placeholder="Automated search"
                          />
                        </Form.Group>

                        <Form.Group controlId="ctrl.save">
                          <Button
                            className="btn btn-primary btn-lg"
                            onClick={handleSubmit}
                            data-source="btnContinue"
                          >
                            <i className="fa fa-search"></i> Save and Continue
                            Search
                          </Button>
                          <Button
                            type="submit"
                            className="btn btn-success btn-lg pull-right"
                            data-source="btnSave"
                          >
                            <i className="fa fa-save"></i> Save
                          </Button>
                        </Form.Group>
                        <div>
                          {" "}
                          {hasSuccess ? (
                            <Alert variant="success pull-left" dismissible>
                              <div>Profile Saved Successfully!</div>
                            </Alert>
                          ) : (
                            ""
                          )}
                          {selectValid ? (
                            <div className="mb-2 alert alert-danger">
                              <div>{selectValid}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          {" "}
                          {dupEmail && (
                            <Alert variant="danger pull-left" dismissible>
                              <div>Email already used by another user!</div>
                            </Alert>
                          )}
                        </div>
                      </Form>
                      <InstitutionReportMdl
                        institution={{
                          institution_id: currentinstid,
                          name: instname,
                        }}
                        statsData={statsdata}
                        statsDataTotal={statstotal}
                        handleClose={handleClose}
                        handleInstitutionPageChange={
                          handleInstitutionPageChange
                        }
                        show={statshow}
                      />
                    </Card.Body>
                  </Card>
                ) : (
                  ""
                )}
              </div>
            )}
          </Col>
        </Row>
      )}
    </main>
  );
};

export default Profile;
