import React, { useState, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Card } from "react-bootstrap";
// import DataTable, { pagination } from "react-data-table-component";
import Sidebar from "../components/SidebarRegistration";
import _ from "underscore";
import HistoryComponent from "./HistoryComponent";
//const dateFormat = require("dateformat");
const axios = require("axios");
const qs = require("query-string");
// const { customStyles } = require("./CMS/tableStyle");

const History = (props) => {
  const [currentTotal, setTotal] = useState(0);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  // const [userid, setUserid] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPerpage, setPerpage] = useState(10);
  // const [currentSort, setSort] = useState();
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [togglerefresh, setTogglerefresh] = useState(false);
  const [isloggedin, setLoggedin] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  document.body.classList.remove("homepage");

  if (!_.isUndefined(props.isPro) ? props.isPro : false)
    document.body.classList.add("pro-user");
  else document.body.classList.add("free-user");
  // useEffect(() => {
  //   document.body.classList.remove("homepage");

  //   if (!_.isUndefined(props.isPro) ? props.isPro : false)
  //     document.body.classList.add("pro-user");
  //   else document.body.classList.add("free-user");
  //   // fetchSearchHistory();
  //   // }, [currentPage, currentPerpage, currentSort]);
  // }, []);
  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handlePerRowsChange = (perPage, page) => {
    setLoading(true);
    setPerpage(perPage);
    setCurrentPage(page);
  };

  const handleSearch = (event, criteria) => {
    var prepare_qs = criteria ? "?" + qs.stringify(JSON.parse(criteria)) : "";
    props.history.push({
      pathname: "/Searchresult",
      search: prepare_qs,
      state: criteria ? JSON.parse(criteria) : "",
    });
    event.preventDefault();
  };

  return (
    <main role="main" className="main mt-2">
      {hasError ? (
        <Row>
          <Col md="1"></Col>
          <Col md="9">
            <Alert variant="warning">
              To access this page you need to{" "}
              <a
                href="/#"
                onClick={(e) => {
                  var element = document.getElementById("nav-login");
                  element.click();
                  e.preventDefault();
                }}
              >
                Login!
              </a>
            </Alert>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md="3">
            <Sidebar {...props} />
          </Col>
          <Col md="9">
            <HistoryComponent
              currentPage={currentPage}
              currentPerpage={currentPerpage}
              currentTotal={currentTotal}
              data={data}
              error={hasError}
              loading={loading}
              setData={setData}
              setErrors={setErrors}
              setLoading={setLoading}
              setLoggedin={setLoggedin}
              setTotal={setTotal}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              handleSearch={handleSearch}
            />
          </Col>
        </Row>
      )}
    </main>
  );
};

export default History;
